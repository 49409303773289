@import './_typography';

@for $i from 1 through 8 {
    $base: $i * 5;

    .vertical-space-#{$base} {
        min-height: #{$base}px;
    }
}

$background: #ffc0cb;

@mixin aspectratio($width, $height) {
    position: relative;

    &::before {
        content: '';
        display: block;
        padding-top: ($height / $width) * 100%;
        width: 100%;
    }

    &.aspectratiocontent {
        background-color: $background;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
