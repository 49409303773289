@import 'assets/sass/index';

a {
    color: var(--link-primary);
    text-decoration: none;

    &:hover {
        color: var(--link-hover);
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
    @include fontsize($fontsize-normal);

    background-color: var(--layout);
    font-family: $default-font;
    -moz-osx-font-smoothing: grayscale;
    // prettier-ignore
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
}

#root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100vh;
    overflow: hidden;
}

.App {
    display: flex;
    height: 100%;
    min-width: 1180px;

    &__header {
        align-items: center;
        background: var(--layout-variant);
        color: var(--text-header);
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        padding-left: $default-padding;
        padding-right: $default-padding;

        &-title {
            @extend .App__header;

            h1 {
                margin: 0;
                padding: 0;

                a {
                    color: var(--link-primary);
                    text-decoration: none;

                    &:hover {
                        color: var(--link-hover);
                    }
                }
            }

            ul {
                a {
                    color: var(--link-primary);
                    padding: 1em;
                    text-decoration: none;

                    &:hover {
                        color: var(--link-hover);
                    }
                }
            }
        }
    }

    section {
        margin-bottom: 1em;
    }

    ul {
        list-style: none;
    }
}

.content {
    background: var(--layout);
    color: var(--text-body);
    font-family: $default-font;
    height: 100%;
    width: 100vw;
}

.header {
    max-height: 64px;
    width: 100%;
    z-index: 999;
}
