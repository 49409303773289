@import '../sass/colors';

// Padding
$default-padding: 1rem;

// Stepper
$bar-height: 4px;
$step-diameter: 30px;
$step-diameter-small: 15px;
$step-background-color: $color-dark-green;
$step-background-color-disabled: $color-gray-20;
$step-check-color: $color-white;
$line-width: calc(100% + #{$default-padding} + #{$step-diameter});
$line-width-small: calc(100% + #{$default-padding} + #{$step-diameter-small});
// This needs to be less than the $line-width above.
// Otherwise, text will stretch farther than the links will.
$max-step-width: 100px;

$default-font: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
